import {
  GET_TEMPLATE,
  GET_ALL_TEMPLATES,
  REMOVE_TEMPLATE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  IS_LOADING,
} from './types';

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: data,
      };

    case GET_TEMPLATE:
      return {
        ...state,
        template: data,
      };

    case GET_ALL_TEMPLATES:
      return {
        ...state,
        templates: data,
      };

    case REMOVE_TEMPLATE:
      return {
        ...state,
        templates: data,
      };

    case CREATE_TEMPLATE:
    case UPDATE_TEMPLATE:
      return state;

    default:
      return state;
  }
};
