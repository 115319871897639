import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HtmlTooltip } from './HtmlTooltip';
import { CustomButton } from './CustomButton';

export const TemplateCard = ({ template, compactView = false, sx = {} }) => {
  const navigate = useNavigate();

  const [showSelectBtnIndex, setShowSelectBtnIndex] = useState(null);

  const isTemplateUnavailable = (template) => {
    return template?.templateName === 'unavailable';
  };

  return (
    <Box
      key={template?.['_id']}
      sx={{
        position: 'relative',
        ...(Object.keys(sx).length
          ? sx
          : {
              maxWidth: '240px',
              minWidth: '200px',
              width: '100%',
              position: 'relative',
              marginTop: '40px',
            }),
      }}
    >
      {!compactView ? (
        <Box
          style={{
            height: 'inherit',
            borderRadius: '8px',
            width: 'max-content',
          }}
          className="imgCustom"
          onMouseOver={() => setShowSelectBtnIndex(template?.['_id'])}
          onMouseLeave={() => setShowSelectBtnIndex(null)}
        >
          <img
            style={{
              ...(Object.keys(sx).length
                ? sx
                : {
                    height: '100%',
                    width: '220px',
                    borderRadius: '8px',
                  }),
            }}
            src={template?.templateImageUrl || `/template1.png`}
          />
          {showSelectBtnIndex === template?.['_id'] ? (
            <CustomButton
              label="Select this Template"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                lineHeight: '20px',
                fontSize: '14px',
                width: 'calc(100% - 40px)',
                transform: 'translate(-50%, -50%)',
                height: 'max-content',
              }}
              onClick={() => navigate(`/templates/${template?.['_id']}`)}
            />
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            height: '160px',
            width: '118px',
            backgroundImage: `url(${
              template?.templateImageUrl
                ? template?.templateImageUrl
                : '/template1.png'
            })`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            borderRadius: '8px',
            position: 'relative',
            '&:hover': {
              cursor: 'pointer',
            },
            overflow: 'hidden',
          }}
          className="imgCustom"
          onClick={() => navigate(`/templates/${template?.['_id']}`)}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              height: '44px',
              backgroundColor: '#E4F0B4',
              zIndex: 3,
              width: '100%',
              borderBottomRadius: '8px',
              padding: '4px 8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#494E57',
              }}
            >
              {template?.templateName}
            </Typography>
          </Box>
        </Box>
      )}

      {isTemplateUnavailable(template?.templateName) ? (
        <HtmlTooltip
          title={
            <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
              This Template is unavailable for your Pricing Plan. Upgrade or
              select another one
            </Typography>
          }
        >
          <img
            src="/locked-mark.svg"
            style={{
              top: '0',
              right: '0',
              position: 'absolute',
              zIndex: '10',
            }}
          />
        </HtmlTooltip>
      ) : null}
    </Box>
  );
};
