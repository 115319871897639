import { useContext, useState, useEffect, Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import jsreport from '@jsreport/browser-client';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Typography,
  Link,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { deepCopy, loadLocalStorage } from 'helpers';
import TemplateContext from 'context/template/TemplateContext';
import DocumentContext from 'context/document/DocumentContext';
import { DropdownComponent } from 'components/common/DropdownComponent';
import { CustomizedDialog } from 'components/common/Dialog';
import CoreLayout from 'components/layouts/CoreLayout';
import { InputField } from 'components/common/InputField';
// import { CustomTagsComponent } from 'components/common/CustomTagsComponent';
import { Alert } from 'components/common';

// const TagComponent = ({ tag, onDelete, tagIndex, tagColor }) => {
//   return (
//     <Chip
//       key={tagIndex}
//       label={tag.name}
//       variant="outlined"
//       size="small"
//       sx={{
//         mr: 0.5,
//         borderColor: tagColor,
//         borderWidth: '2px',
//       }}
//       onDelete={onDelete}
//     />
//   );
// };

const SelectableLineComponent = ({
  lineText,
  lineLocation,
  isSelected,
  onClick,
}) => {
  return (
    <li
      className="cursor-pointer"
      style={{
        order: lineLocation,
        marginBottom: '10px',
        borderLeft: isSelected ? '5px solid #5b5bf7' : '3px solid grey',
        color: isSelected ? '#5b5bf7' : 'black',
        paddingLeft: '10px',
      }}
      onClick={onClick}
    >
      {lineText}
    </li>
  );
};

export const CreateDocumentPage = () => {
  const { state: templateState, getTemplateData } = useContext(TemplateContext);
  const {
    state: documentState,
    getDocumentData,
    createDocument,
    updateDocument,
  } = useContext(DocumentContext);
  const { documentId, templateId } = useParams();

  const [documentName, _] = useState('New Document'); // TODO
  const [isEditMode, setIsEditMode] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedSectionLine, setSelectedSectionLine] = useState({});
  const [positionToAddSection, setPositionToAddSection] = useState(0);
  const [nameOfSectionToAdd, setNameOfSectionToAdd] = useState('');
  const [showEditDocumentName, setShowEditDocumentName] = useState(false);
  const [loadedDocumentName, setLoadedDocumentName] = useState(documentName); // TODO improve

  const [newTemplateLine, setNewTemplateLine] = useState({});
  const [tags, setTags] = useState([]);
  const [showTemplateLineForm, setShowTemplateLineForm] = useState(false);
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [showEditInfoAlert, setShowEditInfoAlert] = useState(false);
  const [isEditingLine, setIsEditingLine] = useState(false);

  const [filteredLinesData, setFilteredLinesData] = useState([]);
  const [userEntriesFilter, setUserEntriesFilter] = useState({
    linesFilter: '',
    tagsFilter: '',
  });

  const handleFilterChange = (value = '', filterType = '') => {
    if (
      !userEntriesFilter?.linesFilter &&
      !userEntriesFilter?.tagsFilter &&
      !value
    ) {
      setFilteredLinesData(selectedSection?.templateLines || []);
    }

    let newFilterData = userEntriesFilter;
    if (filterType) newFilterData = { ...newFilterData, [filterType]: value };

    setUserEntriesFilter(newFilterData);

    const filteredLines = selectedSection?.templateLines?.filter((line) => {
      return (
        line.lineText
          .toLowerCase()
          .includes(newFilterData?.linesFilter.toLowerCase()) &&
        (line.lineTags?.length === 0 ||
          line.lineTags.some((tag) =>
            tag.tagName
              .toLowerCase()
              .includes(newFilterData?.tagsFilter.toLowerCase())
          ))
      );
    });

    setFilteredLinesData(filteredLines);
  };

  useEffect(() => {
    if (templateId) {
      setIsEditMode(false);
      getTemplateData(templateId);
    } else if (documentId) {
      setIsEditMode(true);
      getDocumentData(documentId); // fetching document data from DB
    }
  }, [templateId, documentId]);

  useEffect(() => {
    setDocumentData(documentState?.document);
    if (documentState?.document && Object.keys(documentState?.document).length)
      getTemplateData(documentState?.document?.templateId);
  }, [documentState?.document]);

  useEffect(() => {
    setTemplateData(templateState?.template);
    // check last updated date in templateState?.template
    // compare it with last updated date in loadLocalStorage(templateState?.template?.documentName)
    const localStorageData =
      loadLocalStorage(documentName) ||
      loadLocalStorage(templateState?.template?.documentName);

    if (!localStorageData) {
      // change condition when DB is implemented and we have updated date
      // if last updated date in local storage is older than the one in templateState?.template
      //   setUpdatedSectionsData(templateState?.template?.sections);
      //   setLoadeddocumentName(templateState?.template?.documentName); // TODO temporary
    } else {
      // if last updated date in local storage is newer than the one in templateState?.template
      // setUpdatedSectionsData(loadLocalStorage(templateState?.template?.documentName)?.sections);
      //   setUpdatedSectionsData(localStorageData?.sections);
    }
  }, [templateState?.template]);

  useEffect(() => {
    const selectedSectionData = documentData?.sections?.find(
      (section) => section.sectionNo === selectedSection?.sectionNo
    );
    const selectedSectionLineData = selectedSectionData?.allocatedLines?.find(
      (line) => line.originalLineId === selectedSectionLine?.originalLineId
    );

    setSelectedSection(selectedSectionData);
    setSelectedSectionLine(selectedSectionLineData);
    // setSelectedSectionLine({}); // todo improve
  }, [documentData]);

  useEffect(() => {
    handleFilterChange();
  }, [selectedSection]);

  const sectionsData = useMemo(() => {
    const tmp = documentData?.sections?.map((section, sIndex) => {
      let newAllocatedLines = section?.allocatedLines || [];

      if (
        newAllocatedLines.length < (section.maxLinesNo || 1) &&
        ['Multiple Lines Single Column', 'Multiple Lines Dual Column'].includes(
          section?.sectionStyle
        )
      ) {
        [
          ...Array((section.maxLinesNo || 1) - section?.allocatedLines?.length),
        ].forEach((_, index) => {
          newAllocatedLines.push({
            lineText: 'Empty',
            lineLocation: section?.allocatedLines?.length + index + 1,
            originalLineId:
              2 + index + 1 * 2 + sIndex + newAllocatedLines.length, // todo improve
            lineVersion: 0,
            lineAdded: '01/01/2022 17:23', // todo improve
          });
        });
      } else if (!section?.allocatedLines?.length) {
        newAllocatedLines.push({
          lineText: 'Empty',
          lineLocation: 1,
          originalLineId: 1 + sIndex + newAllocatedLines.length, // todo improve
          lineVersion: 0,
          lineAdded: '01/01/2022 17:23', // todo improve
        });
      }

      return {
        ...section,
        allocatedLines: newAllocatedLines,
        sectionNo:
          (section?.sectionNo || 1) + (documentData?.sections?.length || 0),
      };
    });

    return tmp;
  }, [documentData]);

  const noEntriesAfterFilter = useMemo(() => {
    if (
      !userEntriesFilter.linesFilter &&
      !userEntriesFilter.tagsFilter &&
      (!filteredLinesData || filteredLinesData?.length === 0)
    ) {
      return 'No lines added for chosen section.';
    }

    let tmp = 'No user entries found containing: ';

    if (userEntriesFilter.linesFilter) {
      tmp = tmp.concat(userEntriesFilter.linesFilter, ' in text');
    }

    if (userEntriesFilter.tagsFilter) {
      if (userEntriesFilter.linesFilter) {
        tmp = tmp.concat(', and ');
      }
      tmp = tmp.concat(userEntriesFilter.tagsFilter, ' in tags.');
    }

    return tmp;
  }, [userEntriesFilter]);

  const getSectionByNameFromTemplate = (sectionName) =>
    templateState?.template?.sections?.find(
      (section) => section.sectionName === sectionName
    );

  const handleClose = () => setShowAddSectionModal(false);

  const handleAddSection = () => {
    setShowAddSectionModal(false);

    const sectionData = getSectionByNameFromTemplate(
      nameOfSectionToAdd || templateData?.sections[0].sectionName
    );

    if (!sectionData) return;

    let sectionNo =
      (sectionData?.sectionNo || 1) + documentData?.sections?.length;

    const oldDocumentData = deepCopy(documentData?.sections || []); // sections already in the document
    oldDocumentData.splice(positionToAddSection, 0, {
      ...sectionData,
      sectionNo,
    }); // put new section at the position "positionToAddSection" in the list of sections in the document

    setDocumentData({ ...documentData, sections: oldDocumentData }); // update document data
    // TODO save updated document data in local storage

    setNameOfSectionToAdd('');
    // add template lines from template data to document data
  };

  const handleAddTemplateLine = () => {
    const newSectionData = deepCopy(selectedSection);

    if (isEditingLine) {
      setIsEditingLine(false);
      let item = newSectionData?.templateLines?.find(
        (line) => line?.lineId === newTemplateLine?.lineId
      );
      if (!item) return;

      item.lineText = newTemplateLine.lineText || item?.lineText;
      item.lineTags = tags || item?.lineTags;
    } else {
      const newLineId = Math.floor(Math.random() * 10000000);
      if (newTemplateLine) {
        newSectionData?.templateLines.splice(0, 0, {
          lineId: newLineId,
          lineVersion: 0,
          lineText: newTemplateLine.lineText || '',
          lineTags: tags || [],
        });
      }
    }

    updateDocumentSection(newSectionData);
    setNewTemplateLine({});
    setTags([]);
    setShowTemplateLineForm(false);
  };

  const openAddSectionModal = (index) => {
    setShowAddSectionModal((oldValue) => !oldValue);
    setPositionToAddSection(index);
  };

  const handleDeleteSection = (index) => {
    let updatedDocumentSections = deepCopy(documentData?.sections);
    updatedDocumentSections.splice(index, 1);
    setDocumentData({ ...documentData, sections: updatedDocumentSections });

    // update data in local storage
  };

  const updateDocumentSection = (updatedSection) => {
    const selectedSectionIndex = documentData?.sections?.findIndex(
      (section) => section.sectionNo === selectedSection?.sectionNo
    );

    const updatedSections = deepCopy(documentData?.sections);
    updatedSections[selectedSectionIndex] = updatedSection;

    const updatedDocument = { ...documentData, sections: updatedSections };

    setDocumentData(updatedDocument);
  };

  const handleDeleteUserEntry = (lineId) => {
    if (!lineId && lineId !== 0) {
      console.log('selected line has no lineId');
      return;
    }

    let updatedSection = deepCopy(selectedSection);
    updatedSection.templateLines = updatedSection?.templateLines?.filter(
      (line) => line.lineId !== lineId
    );

    updateDocumentSection(updatedSection);
  };

  const handleSelectSection = (section) => {
    if (
      selectedSection?.sectionNo === section?.sectionNo &&
      !selectedSectionLine
    ) {
      setSelectedSection(null);
      setShowEditInfoAlert(false);
    } else {
      setSelectedSection(section);
      setShowEditInfoAlert(true);
    }

    setShowInfoAlert(false);
    setSelectedSectionLine(null);
  };

  const handleSelectSectionLine = (section, line) => {
    setShowEditInfoAlert(false);

    if (
      selectedSection?.sectionNo === section?.sectionNo &&
      line?.originalLineId === selectedSectionLine?.originalLineId
    ) {
      setSelectedSection(null);
      setSelectedSectionLine(null);
      setShowInfoAlert(false);
    } else {
      setSelectedSection(section);
      setSelectedSectionLine(line);
      setShowInfoAlert(true);
    }
  };

  const allocateLine = (line) => {
    const { lineVersion, lineText, lineTags, lineId } = line;

    if (!selectedSectionLine) {
      setIsEditingLine(true);
      setShowTemplateLineForm(true);
      setNewTemplateLine({
        lineText: lineText || '',
        lineTags: lineTags || [],
        lineId: lineId,
      });
      setTags(lineTags || []);
      return;
    }

    const updatedLine = {
      lineVersion,
      lineText,
      lineTags,
      originalLineId: lineId,
      lineLocation: selectedSectionLine?.lineLocation,
    };

    const selectedLineIndex = selectedSection?.allocatedLines?.findIndex(
      (line) => line.lineLocation === selectedSectionLine?.lineLocation
    );

    let updatedSection = deepCopy(selectedSection);
    updatedSection.allocatedLines[selectedLineIndex] = updatedLine;

    updateDocumentSection(updatedSection);
    setShowInfoAlert(false);
    setSelectedSection({});
    setSelectedSectionLine({});
  };

  const removeAllocatedLine = () => {
    let updatedSection = deepCopy(selectedSection);
    updatedSection.allocatedLines = updatedSection.allocatedLines.filter(
      (line) => line.lineLocation !== selectedSectionLine?.lineLocation
    );
    updateDocumentSection(updatedSection);
    setShowInfoAlert(false);
    setSelectedSection({});
    setSelectedSectionLine({});
  };

  const handleSave = () => {
    const folderId = localStorage.getItem('selectedFolderId');
    if (isEditMode) {
      updateDocument(documentData.id, documentData);
    } else {
      createDocument({
        folderId,
        templateId,
        templateName: templateData?.templateName,
        templateVersion: templateData?.templateVersion,
        actions: [
          {
            auditLines: [
              {
                auditId: 1,
                type: 'automated',
                date: '2024-01-29T09:00:00.000Z',
                note: 'Document created.',
              },
            ],
            documentMetaData: {
              emailSent: 'user@example.com',
              company: 'Example Corp',
              templateVersion: 1,
              NotesAtSent: 'Initial draft',
            },
          },
        ],
        ...documentData,
        sections: [
          {
            sectionNo: 1,
            sectionName: 'Introduction',
            templateLines: [
              {
                lineId: 101,
                lineVersion: 0,
                lineText: 'Welcome to the Annual Report...',
                lineAdded: '01/01/2022 17:23',
                lineUpdated: '01/01/2022 17:23',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 101,
                lineVersion: 0,
                lineText: 'Welcome to the Annual Report...',
                lineAdded: '2024-01-29T09:00:00.000Z',
              },
            ],
          },
        ],
      });
    }
  };

  const renderPDF = async () => {
    jsreport.headers['Authorization'] = 'Basic ' + btoa('email:pass');
    jsreport.serverUrl = '<URL>';

    const report = await jsreport.render({
      template: {
        name: '/Invoice/cv',
      },
      data: {
        _id: '670fc880038dffc3a8e008be',
        name: 'Marta Tolevska',
        folderId: '66fe7534b2745c384dc6ec63',
        templateId: '66fe77c05f262416276e9e84',
        draft: false,
        favorite: false,
        position: 'Manager',
        companyName: 'Acme Corp',
        documentName: 'Contract Agreement',
        templateVersion: 1,
        email: 'tole.vskam.8@gmail.com',
        actions: [],
        sections: [
          {
            sectionNo: 1,
            sectionName: 'Title',
            sectionStyle: 'Single Line Single Column',
            sectionDescription: 'John Doe',
            isMandatory: true,
            sectionId: 'title_single_line',
            templateLines: [
              {
                lineId: 101,
                lineVersion: 1,
                lineText: 'This is the introduction section.',
                lineTags: [
                  {
                    tagName: 'Important',
                    tagColour: 'Red',
                    tagAdded: '2023-09-01',
                    _id: '670fc880038dffc3a8e008c1',
                  },
                ],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a8e008c0',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 101,
                lineVersion: 1,
                lineText: 'This is the introduction section.',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a8e008c2',
              },
            ],
            _id: '670fc880038dffc3a8e008bf',
          },
          {
            sectionNo: 2,
            sectionName: 'Occupation',
            sectionStyle: 'Single Line Single Column',
            sectionDescription: 'Engineer',
            isMandatory: true,
            sectionId: 'occupation_single_line',
            templateLines: [
              {
                lineId: 10,
                lineVersion: 1,
                lineText: 'Engineer',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a8e008c4',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 10,
                lineVersion: 1,
                lineText: 'Engineer.',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a8e008c5',
              },
            ],
            _id: '670fc880038dffc3a8e008c3',
          },
          {
            sectionNo: 3,
            sectionName: 'Summary',
            sectionStyle: 'Single Line Single Column',
            sectionDescription: 'Software Engineer with 5 years experience',
            isTextArea: true,
            isMandatory: true,
            sectionId: 'summary_single_line',
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText:
                  'Software engineer with 5 years of experience in building and improving software products',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a8e008c7',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 1,
                lineVersion: 1,
                lineText:
                  'Software engineer with 5 years of experience in building and improving software products.',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a8e008c8',
              },
            ],
            _id: '670fc880038dffc3a8e008c6',
          },
          {
            sectionName: 'Portfolio Link',
            sectionId: 'portfolio_link_multiple_lines',
            sectionStyle: 'Multiple Lines Single Column',
            sectionDescription:
              'https://github.com/martatolevska; https://www.linkedin.com/in/martatolevska',
            maxLines: 3,
            isMandatory: true,
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText: 'https://github.com/martatolevska',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a8e008c7',
              },
              {
                lineId: 2,
                lineVersion: 1,
                lineText: 'https://www.linkedin.com/in/martatolevska',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a8e008c8',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 1,
                lineVersion: 1,
                lineText: 'https://github.com/martatolevska',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a8e008c7',
              },
              {
                lineLocation: 2,
                originalLineId: 2,
                lineVersion: 1,
                lineText: 'https://www.linkedin.com/in/martatolevska',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a8e008c8',
              },
            ],
            _id: '670fc880038dffc3a8e009c6',
          },
          {
            sectionName: 'Email',
            sectionId: 'email_multiple_lines',
            sectionStyle: 'Multiple Lines Single Column',
            sectionDescription: 'email@gmail.com',
            maxLines: 2,
            isMandatory: true,
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText: 'tolevska.m8@gmail.com',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a8e098c7',
              },
              {
                lineId: 2,
                lineVersion: 1,
                lineText: 'tolevska@gmail.com',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a9e008c8',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 2,
                lineVersion: 1,
                lineText: 'tolevska@gmail.com',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a9e008c8',
              },
            ],
            _id: '670fc880038dffc3a8z009c6',
          },
          {
            sectionName: 'Phone',
            sectionId: 'phone_multiple_lines',
            sectionStyle: 'Multiple Lines Single Column',
            sectionDescription: '+1 (555) 555-5555; +1 (333) 333-3333',
            maxLines: 3,
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText: '+1 (555) 555-5555',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a1e098c7',
              },
              {
                lineId: 2,
                lineVersion: 1,
                lineText: '+1 (333) 333-3333',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a0e008c8',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 1,
                lineVersion: 1,
                lineText: '+1 (555) 555-5555',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a1e098c7',
              },
            ],
            _id: '670fc880038dffc3a8z009c6',
          },
          {
            sectionName: 'Skills',
            sectionId: 'skills_multiple_lines',
            sectionStyle: 'Multiple Lines Single Column',
            sectionDescription: 'Teamwork; Attention to details',
            isMandatory: true,
            maxLines: 7,
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText: 'Teamwork',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a1e099c7',
              },
              {
                lineId: 2,
                lineVersion: 1,
                lineText: 'Active Listening',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a0e008c8',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 1,
                lineVersion: 1,
                lineText: 'Teamwork',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a1e099c7',
              },
              {
                lineLocation: 1,
                originalLineId: 2,
                lineVersion: 1,
                lineText: 'Active Listening',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a0e008c8',
              },
            ],
            _id: '670fc880038dffc3a8z009c6',
          },
          {
            sectionName: 'Tools',
            sectionId: 'tools_multiple_lines',
            sectionStyle: 'Multiple Lines Single Column',
            sectionDescription: 'VSCode, 7/10; Git, 7/10; Node.js, 5/10',
            maxLines: 7,
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText: 'VSCode, 8/10',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a1e099c7',
              },
              {
                lineId: 2,
                lineVersion: 1,
                lineText: 'Git, 7/10',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a0e008c8',
              },
              {
                lineId: 3,
                lineVersion: 1,
                lineText: 'Node.js, 5/10',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a0e008c8',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 2,
                lineVersion: 1,
                lineText: 'Git, 7/10',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a1e099c7',
              },
              {
                lineLocation: 2,
                originalLineId: 3,
                lineVersion: 1,
                lineText: 'Node.js, 5/10',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a0e008c8',
              },
            ],
            _id: '670fc880038dffc3a8z009c6',
          },
          {
            sectionName: 'Languages',
            sectionId: 'languages_multiple_lines',
            sectionStyle: 'Multiple Lines Single Column',
            sectionDescription: 'English, 8/10; German, 4/10;',
            maxLines: 4,
            templateLines: [
              {
                lineId: 1,
                lineVersion: 1,
                lineText: 'English, 8/10',
                lineTags: [],
                lineAdded: '2023-09-01',
                lineUpdated: '2023-09-02',
                _id: '670fc880038dffc3a1e099c7',
              },
            ],
            allocatedLines: [
              {
                lineLocation: 1,
                originalLineId: 1,
                lineVersion: 1,
                lineText: 'English, 8/10',
                lineAdded: '2023-09-01',
                _id: '670fc880038dffc3a1e099c7',
              },
            ],
            _id: '670fc880038dffc3a8z009c6',
          },
        ],
        __v: 0,
      },
    });
    document.getElementById('pdfPreview').data = await report.toObjectURL();

    // report.openInWindow({ title: 'myreport' });
    // report.download({ title: 'myreport' });
  };

  return (
    <CoreLayout>
      <object id="pdfPreview" data="" width="800" height="500"></object>

      {templateData?.sections ? (
        <>
          <Grid container sx={{ my: '30px' }}>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                justifyContent: showEditDocumentName ? 'center' : 'flex-start',
              }}
            >
              <Typography variant="h4">
                {documentData?.documentName || 'New Document'}

                <IconButton
                  aria-label="edit"
                  color="inherit"
                  size="medium"
                  sx={{ ':hover': { color: '#76b1ce' } }}
                  onClick={() => {
                    setShowEditDocumentName(true);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Typography>
              {showEditDocumentName ? (
                <>
                  <InputField
                    name="documentName"
                    label="Document name"
                    placeholder="Enter document name"
                    value={documentData?.documentName}
                    style={{ mb: 3, mt: 2 }}
                    onChange={(_, value) => {
                      setDocumentData({
                        ...documentData,
                        documentName: value,
                      });
                      setLoadedDocumentName(value);
                    }}
                    endAdornment={
                      <IconButton
                        size="medium"
                        sx={{ color: 'green' }}
                        onClick={() => {
                          setShowEditDocumentName(false);
                        }}
                      >
                        <CheckIcon fontSize="inherit" />
                      </IconButton>
                    }
                  />
                </>
              ) : null}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
                gap: '20px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button variant="outlined" sx={{ mr: 2 }} onClick={renderPDF}>
                  Download
                </Button>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Box>
              {isEditMode ? (
                <Link
                  href={`/audit/${documentData?.documentName}`}
                  sx={{ color: 'black', cursor: 'pointer' }}
                >
                  View history of document
                </Link>
              ) : null}
            </Grid>
          </Grid>

          {showInfoAlert ? (
            <Alert
              message="Select an item from user entry list that you want to replace the chosen line"
              severity="warning"
              backgroundColor="#97c294"
              isOpen={showInfoAlert}
              closable={false}
            />
          ) : null}

          {showEditInfoAlert ? (
            <Alert
              message="The selected section is now in edit mode. You can choose an user entry to edit."
              severity="warning"
              backgroundColor="#76b1ce"
              isOpen={showEditInfoAlert}
              closable={false}
            />
          ) : null}
          <Grid container>
            <Grid item xs={6.5}>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <InputField
                    name="companyName"
                    label="Company name"
                    placeholder="Enter company name"
                    fullWidth={true}
                    value={documentData?.companyName}
                    onChange={(_, value) => {
                      setDocumentData({
                        ...documentData,
                        companyName: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    name="position"
                    label="Position"
                    placeholder="Enter position"
                    value={documentData?.position}
                    fullWidth={true}
                    onChange={(_, value) => {
                      setDocumentData({
                        ...documentData,
                        position: value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 5 }}>
            <Grid
              item
              xs={6.5}
              sx={{
                border: '1px solid #dfd9d9',
                borderRadius: '5px',
                height: 'max-content',
                p: '15px',
                mr: 2,
              }}
            >
              <Grid container>
                {sectionsData?.map((section, sectionIndex) => {
                  return (
                    <Fragment key={sectionIndex}>
                      <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Box
                          sx={{
                            width: '30px',
                            p: '5px',
                            my: 1,
                            mr: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: 'solid #dfd9d9 1px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                          }}
                        >
                          <IconButton
                            disableRipple
                            onClick={() => openAddSectionModal(0)}
                            sx={{
                              width: '100%',
                              height: '100%',
                              ':hover, :visited': {
                                borderRadius: '0',
                              },
                            }}
                          >
                            <AddIcon
                              sx={{
                                color: 'grey',
                                height: '25px',
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            borderRadius: '5px',
                            my: 1,
                            p: '10px',
                            pr: '22px',
                            position: 'relative',
                            width: '100%',
                            border:
                              section?.sectionNo === selectedSection?.sectionNo
                                ? 'solid blue 2px'
                                : 'solid #dfd9d9 1px',
                            // if item is selected section then add thicker border
                          }}
                        >
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDeleteSection(sectionIndex);
                            }}
                            sx={{
                              position: 'absolute',
                              right: 0,
                              top: '2px',
                              color: 'red',
                            }}
                          >
                            <Close />
                          </IconButton>
                          <Typography
                            sx={{
                              fontWeight: '700',
                              color:
                                section?.sectionNo ===
                                selectedSection?.sectionNo
                                  ? 'blue'
                                  : 'black',
                              ':hover': { cursor: 'pointer' },
                            }}
                            onClick={() => handleSelectSection(section)}
                          >
                            {section?.sectionName}
                          </Typography>

                          <ul
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              listStyle: 'none',
                              paddingInlineStart: '15px',
                              mb: 0,
                            }}
                          >
                            {section?.allocatedLines?.length
                              ? section.allocatedLines.map(
                                  (allocatedLine, allocatedLineIndex) => (
                                    <SelectableLineComponent
                                      key={allocatedLineIndex}
                                      lineText={allocatedLine?.lineText}
                                      lineLocation={allocatedLine?.lineLocation}
                                      isSelected={
                                        allocatedLine?.originalLineId ===
                                        selectedSectionLine?.originalLineId
                                      }
                                      onClick={() =>
                                        handleSelectSectionLine(
                                          section,
                                          allocatedLine
                                        )
                                      }
                                    />
                                  )
                                )
                              : null}
                          </ul>
                        </Box>
                      </Grid>
                    </Fragment>
                  );
                })}

                <Grid
                  item
                  sx={{
                    border: 'solid #dfd9d9 1px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    ml: 'auto',
                    width: 'calc(100% - 50px)',
                  }}
                >
                  <IconButton
                    disableRipple
                    onClick={() => openAddSectionModal(sectionsData?.length)}
                    sx={{
                      height: '100%',
                      width: '100%',
                      ':hover, :visited': {
                        borderRadius: '0',
                      },
                    }}
                  >
                    <AddIcon sx={{ color: 'grey', height: '25px' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={5}
              sx={{
                borderRadius: '5px',
                border: '1px solid #dfd9d9',
                height: 'auto',
                p: '15px',
                ml: 'auto',
              }}
            >
              {selectedSection && (
                <Grid container>
                  {showInfoAlert &&
                  selectedSectionLine?.lineText !== 'Empty' ? (
                    <Grid item xs={12} sx={{ mb: 0 }}>
                      <Button onClick={() => removeAllocatedLine()}>
                        Remove allocated line
                      </Button>
                    </Grid>
                  ) : null}

                  {!showTemplateLineForm ? (
                    <Grid item xs={12}>
                      <Button
                        sx={{ display: 'block', float: 'right', mb: 3 }}
                        variant="outlined"
                        onClick={() => {
                          setShowTemplateLineForm(true);
                        }}
                      >
                        Add new entry
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderRadius: '5px',
                        border: 'solid #dfd9d9 1px',
                        mb: 2,
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <InputField
                        name="templateLine"
                        placeholder="Enter text"
                        value={newTemplateLine?.lineText || ''}
                        fullWidth
                        style={{ mb: 1 }}
                        multiline={true}
                        minRows={2}
                        maxRows={4}
                        onChange={(_, value) => {
                          setNewTemplateLine({
                            lineText: value,
                            lineTags: newTemplateLine?.lineTags || [],
                            ...(newTemplateLine?.lineId && {
                              lineId: newTemplateLine?.lineId,
                            }),
                          });
                        }}
                      />
                      {/* <CustomTagsComponent
                        tags={tags}
                        setTags={setTags}
                        renderTag={({ tag, deleteButtonText, onDelete }) => (
                          <TagComponent
                            tag={tag}
                            tagIndex={0}
                            tagColor={'grey'}
                            deleteButtonText={deleteButtonText}
                            onDelete={onDelete}
                          />
                        )}
                      /> */}

                      <Button
                        disabled={!newTemplateLine?.lineText}
                        sx={{ float: 'right' }}
                        onClick={handleAddTemplateLine}
                      >
                        Add line
                      </Button>
                      <Button
                        sx={{ float: 'right' }}
                        onClick={() => setShowTemplateLineForm(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <InputField
                      name="linesFilter"
                      label="Search lines"
                      value={userEntriesFilter.linesFilter}
                      clearable
                      onChange={(_, value) =>
                        handleFilterChange(value, 'linesFilter')
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      name="tagsFilter"
                      label="Search tags"
                      value={userEntriesFilter.tagsFilter}
                      clearable
                      onChange={(_, value) =>
                        handleFilterChange(value, 'tagsFilter')
                      }
                    />
                  </Grid>

                  {filteredLinesData?.length ? (
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        fontWeight: 600,
                        display: 'block',
                        width: '100%',
                      }}
                    >
                      User entries:
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        display: 'block',
                        width: '100%',
                      }}
                    >
                      {noEntriesAfterFilter}
                    </Typography>
                  )}

                  {filteredLinesData?.map((templateLine, templateLineIndex) => (
                    <Grid
                      item
                      xs={12}
                      key={templateLineIndex}
                      sx={{
                        borderRadius: '5px',
                        border: 'solid #dfd9d9 1px',
                        mb: 1,
                        p: 1,
                        position: 'relative',
                      }}
                    >
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteUserEntry(templateLine?.lineId);
                        }}
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: '2px',
                          color: 'red',
                        }}
                      >
                        <Close />
                      </IconButton>
                      <Typography
                        sx={{ pr: 3, ':hover': { cursor: 'pointer' } }}
                        onClick={() => allocateLine(templateLine)}
                      >
                        {templateLine?.lineText}
                      </Typography>
                      <Box>
                        {templateLine?.lineTags?.map((lineTag, tagIndex) => (
                          <Chip
                            key={tagIndex}
                            label={lineTag?.tagName}
                            variant="outlined"
                            size="small"
                            sx={{
                              mt: 1,
                              mr: 0.5,
                              borderColor: 'gray',
                              borderWidth: '2px',
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>

          {templateData?.sections && (
            <CustomizedDialog
              isOpen={showAddSectionModal}
              title="Choose section to add"
              toggle={handleClose}
              actions={[
                {
                  label: 'Add +',
                  onClick: handleAddSection,
                  variant: 'contained',
                },
              ]}
            >
              <DropdownComponent
                label="Section name"
                defaultValue={templateData?.sections[0].sectionName}
                style={{
                  width: '100%',
                  mt: 2,
                }}
                options={templateData.sections.map(
                  (section) => section.sectionName
                )}
                onChange={(_, value) => {
                  setNameOfSectionToAdd(value);
                }}
              />
            </CustomizedDialog>
          )}
        </>
      ) : null}
    </CoreLayout>
  );
};
