import { GET_AUDIT } from './types';

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    case GET_AUDIT:
      return {
        ...state,
        audit: { ...state.audit, ...data },
      };

    default:
      return state;
  }
};
