import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { CustomButton } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import { useContext, useEffect, useState } from 'react';
import DocumentContext from 'context/document/DocumentContext';
import { deepCopy, formatDateFromMiliseconds, removeIdKeys } from 'helpers';
import { DialogWithForm } from 'components/common/DialogWithForm';
import { SaveCVDialog } from 'components/common/SaveCVDialog';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';

export const ViewDocument = ({ pageName }) => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [documentData, setDocumentData] = useState({});
  const [anchorElement, setAnchorElement] = useState(null);
  const [foldersList, setFoldersList] = useState([]);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showConfirmationDeletionDialog, setShowConfirmationDeletionDialog] =
    useState(false);

  const {
    state: documentState,
    getDocumentData,
    deleteDocument,
    toggleFavourite,
    getFolders,
    createDocument,
    updateDocument,
    createFolderAndSaveDocument,
  } = useContext(DocumentContext);

  useEffect(() => {
    getFolders();
  }, []);

  useEffect(() => {
    setFoldersList(documentState?.folders);
  }, [documentState?.folders]);

  useEffect(() => {
    getDocumentData(documentId);
  }, [documentId]);

  useEffect(() => {
    setDocumentData(documentState?.document);
  }, [documentState?.document]);

  const duplicateDocument = () => {
    setShowDuplicateModal(true);
    // navigate(`/createDocument/duplicate/${documentId}`);
  };

  const handleOpenMenu = (event) => {
    setIsOpen(true);
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
    setIsOpen(null);
  };

  const handleDeleteDocument = () => {
    deleteDocument(documentId);
    setShowDeletionDialog(true);
    handleClose();
  };

  const editAttributes = () => {
    setShowSaveDialog(true);
    handleClose();
  };

  const handleChangeDocumentMetadata = (key, value) => {
    setDocumentData({ ...documentData, [key]: value });
  };

  const onSaveAsDraft = () => {
    //
  };

  const handleSaveToFolder = (mode = 'create') => {
    let { __v, favorite, draft, email, folderName, ...dataToSave } =
      deepCopy(documentData);
    removeIdKeys(dataToSave);

    if (folderName && folderName !== documentState?.document?.folderName) {
      createFolderAndSaveDocument(
        folderName,
        { ...dataToSave, draft: true },
        mode === 'edit' ? 'edit' : 'create'
      );
      // !documentmetadata required...
    } else {
      if (mode === 'edit') {
        const { templateName, templateImageUrl, ...updatedData } = dataToSave;
        const newSections = updatedData?.sections?.map((section) => {
          const { sectionNo, ...sectionData } = section;
          return sectionData;
        });

        const newActions = updatedData?.actions?.map((action) => {
          return {
            ...action,
            documentMetaData: {
              emailSent: 'kon@kon.com',
              company: 'addmyask com',
              templateVersion: 3,
              NotesAtSent:
                'I am sending this document to company addmyask as it is needed by XYZ',
            },
          };
        });

        updateDocument(documentId, {
          ...updatedData,
          actions: newActions,
          sections: newSections,
        });
      } else {
        createDocument({ ...dataToSave, draft: true });
      }
    }
  };

  return (
    <CoreLayout
      customPageWrapperLayout={true}
      pageTitle="CV Constructor"
      backLink={pageName === 'drafts' ? '/drafts' : '/my-cvs'}
      backLinkLabel={
        pageName === 'drafts' ? 'Back to Drafts List' : 'Back to CVs List'
      }
    >
      <Grid container sx={{ p: 0 }}>
        <Grid item xs={7}>
          <img
            src={documentData?.templateImageUrl || '/viewTemplate1.png'}
            alt=""
            style={{
              maxWidth: '100%',
              marginLeft: '-25px',
              marginTop: '-5px',
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: '4px',
            }}
          >
            <Typography
              sx={{
                py: '6px',
                textSize: '20px',
                lineHeight: '24px',
                fontWeight: '700',
                color: '#494E57',
              }}
            >
              {documentData?.documentName}
            </Typography>
            <CustomButton
              style={{
                backgroundColor: 'transparent',
                minWidth: 0,
                width: '32px',
                height: '32px',
                padding: '0px',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'no-wrap',
              }}
              imgSrc="/dots-menu-icon.svg"
              iconStyle={{
                height: 'auto',
              }}
              onClick={(event) => handleOpenMenu(event)}
            />

            <Menu
              id="basic-menu"
              anchorEl={anchorElement}
              open={isOpen}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    // overflow: 'visible',
                    boxShadow: '0px 8px 28px 0px rgba(34, 42, 2, 0.12)',
                    borderRadius: '8px',
                    // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    ml: 3,
                    width: '220px',
                  },
                },
              }}
            >
              <MenuItem onClick={duplicateDocument}>Duplicate</MenuItem>
              <MenuItem onClick={editAttributes}>Edit attributes</MenuItem>
              <MenuItem onClick={() => setShowConfirmationDeletionDialog(true)}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
          {pageName !== 'drafts' ? (
            <Box>
              <FormControlLabel
                label="Favourite"
                sx={{
                  '.MuiFormControlLabel-label': {
                    color: '#494E57',
                    fontSize: '14px',
                    lineHeight: '20px',
                  },
                  '.MuiCheckbox-root .MuiSvgIcon-root': {
                    fill: '#C4DB67',
                  },
                }}
                control={
                  <Checkbox
                    checked={documentData?.favorite}
                    onChange={() => toggleFavourite(documentData?.['_id'])}
                  />
                }
              />
            </Box>
          ) : null}
          <Grid container sx={{ rowGap: '12px', mb: '20px' }}>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '16px', color: '#707682' }}
              >
                {documentData?.draft ? 'Last edit' : 'Completed'}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: '#494E57', lineHeight: '20px' }}
              >
                {documentData?.actions?.[0]?.auditLines?.[0]?.date
                  ? formatDateFromMiliseconds(
                      new Date(
                        documentData?.actions?.[0]?.auditLines?.[0]?.date
                      ).getTime()
                    )
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '16px', color: '#707682' }}
              >
                Template
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: '#494E57', lineHeight: '20px' }}
              >
                {documentData?.templateName || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '16px', color: '#707682' }}
              >
                Position
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: '#494E57', lineHeight: '20px' }}
              >
                {documentData?.position || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '16px', color: '#707682' }}
              >
                Folder
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: '#494E57', lineHeight: '20px' }}
              >
                {documentData?.folderName || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '16px', color: '#707682' }}
              >
                Note
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: '#494E57', lineHeight: '20px' }}
              >
                {documentData?.actions?.[0]?.auditLines?.[0]?.note || '-'}
              </Typography>
            </Grid>
          </Grid>

          {pageName === 'my-cvs' ? (
            <CustomButton
              style={{ width: '100%' }}
              label="Open as a Copy in Constructor"
              onClick={duplicateDocument}
            />
          ) : null}
          {pageName === 'drafts' ? (
            <CustomButton
              style={{ width: '100%' }}
              label="Open in Constructor"
              onClick={() =>
                navigate(`/createDocument/edit/${documentData?._id}`)
              }
            />
          ) : null}
        </Grid>
      </Grid>
      {/* 
      <CustomizedDialog
        isOpen={shouldShowDialog}
        title="Fill with saved CV data"
        toggle={handleClose}
        actions={[
          {
            label: 'Fill Manually',
            onClick: () => {
              navigate('/createDocument/new');
            },
            variant: 'outlined',
          },
          {
            label: 'Fill with CV Data',
            onClick: () => {
              navigate('/createDocument/new', {
                state: { documentId: activeDocument?.documentId },
              });
            },
            disabled: !activeDocument,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      > */}
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <DropdownComponent
            name="filterByFolder"
            defaultValue={folderNamesList[0]}
            style={{
              width: '250px',
              maxWidth: '100%',
              mb: 2,
              mt: 0,
              display: 'block',
            }}
            options={folderNamesList}
            onChange={(_, value) => {
              handleFilterChanged('folder', value);
            }}
          />
          <InputField
            placeholder="Search by keyword"
            value={filters?.keyword}
            fullWidth={true}
            clearable
            onChange={(_, value) => handleFilterChanged('keyword', value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ height: '16px', width: '16px' }} />
              </InputAdornment>
            }
          />
        </Box>
        <DocumentsList
          foldersList={filteredFoldersData}
          activeDocument={activeDocument}
          setActiveDocument={setActiveDocument}
        /> */}
      {/* </CustomizedDialog> */}

      <DialogWithForm
        isOpen={showSaveDialog}
        title="Edit attributes"
        actions={[
          {
            label: 'Cancel',
            onClick: () => {
              setShowSaveDialog(false);
            },
            variant: 'outlined',
          },
          {
            label: 'Save changes',
            onClick: () => {
              setShowSaveDialog(false);
              handleSaveToFolder('edit');
            },
            disabled: false,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      >
        <SaveCVDialog
          foldersList={foldersList}
          data={documentData}
          onChange={handleChangeDocumentMetadata}
          // handleFolderChanged={handleFolderChanged}
        />
      </DialogWithForm>

      <DialogWithForm
        isOpen={showDuplicateModal}
        title="New Duplicated Document"
        actions={[
          {
            label: 'Cancel',
            onClick: () => {
              setShowDuplicateModal(false);
            },
            variant: 'text',
          },
          {
            label: 'Save to Folder',
            onClick: () => {
              handleSaveToFolder('create');
              setShowDuplicateModal(false);
            },
            variant: 'outlined',
          },
          {
            label: 'Open in Constructor',
            onClick: () => {
              setShowSaveDialog(false); // ?do i need this?
              setShowDuplicateModal(false);
              navigate(`/createDocument/copy/${documentId}`);
            },
            disabled: false,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      >
        <SaveCVDialog
          foldersList={foldersList}
          data={documentData}
          onChange={handleChangeDocumentMetadata}
          // handleFolderChanged={handleFolderChanged}
        />
      </DialogWithForm>

      <ConfirmationDialog
        open={showConfirmationDeletionDialog}
        title="Are you sure?"
        descriptionLines={[
          'You are going to delete this CV.',
          'With this action you will lose all its data with no restore option.',
        ]}
        illustrationSrc="/question-illustration.svg"
        secondaryBtnLabel="Cancel"
        secondaryBtnOnClick={() => setShowConfirmationDeletionDialog(false)}
        primaryBtnLabel="Delete CV"
        primaryBtnOnClick={() => {
          setShowConfirmationDeletionDialog(false);
          handleDeleteDocument();
        }}
      />

      <ConfirmationDialog
        open={showDeletionDialog}
        title="Successfully deleted!"
        illustrationSrc="/trash-illustration.svg"
        primaryBtnLabel="OK"
        primaryBtnOnClick={() => {
          setShowDeletionDialog(false);
          navigate(pageName === 'drafts' ? '/drafts' : '/my-cvs');
        }}
      />
    </CoreLayout>
  );
};
