import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { TemplateCard } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import TemplateContext from 'context/template/TemplateContext';

export const AllTemplates = () => {
  const navigate = useNavigate();
  const [showSelectBtnIndex, setShowSelectBtnIndex] = useState(null);

  const [templatesList, setTemplatesList] = useState([]);
  const { state: templateState, getAllTemplates } = useContext(TemplateContext);

  useEffect(() => {
    getAllTemplates();
  }, []);

  useEffect(() => {
    setTemplatesList(templateState?.templates);
  }, [templateState?.templates]);

  const isTemplateUnavailable = (template) => {
    return template?.templateName === 'unavailable';
  };

  return (
    <CoreLayout
      pageTitle="CV Constructor"
      wrapperStyle={{ padding: '0px 60px' }}
    >
      {/* <CardComponent /> */}
      {/* <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '40px',
        }}
      ></Box> */}
      <Typography
        sx={{
          fontSize: '18px',
          lineHeight: '24px',
          color: '#494E57',
          mb: '20px',
        }}
      >
        Please select a CV template to start creating
      </Typography>
      <Grid
        container
        spacing={'40px'}
        sx={{ mt: '-40px', ml: 0, justifyContent: 'flex-start' }}
      >
        {templatesList?.map((template) => (
          <TemplateCard key={template?.['_id']} template={template} />
          // <Box
          //   item
          //   key={index}
          //   sx={{
          //     maxWidth: '240px',
          //     minWidth: '200px',
          //     width: '100%',
          //     position: 'relative',
          //     marginTop: '40px',
          //   }}
          // >
          //   <div
          //     style={{
          //       height: 'inherit',
          //       borderRadius: '8px',
          //       width: 'max-content',
          //     }}
          //     className="imgCustom"
          //     onMouseOver={() => setShowSelectBtnIndex(index)}
          //     onMouseLeave={() => setShowSelectBtnIndex(null)}
          //   >
          //     <img
          //       style={{
          //         height: '100%',
          //         minWidth: '200px',
          //         maxWidth: '240px',
          //         borderRadius: '8px',
          //       }}
          //       src={`/template${index + 1}.png`}
          //     />
          //     {showSelectBtnIndex === index ? (
          //       <CustomButton
          //         label="Select this Template"
          //         style={{
          //           position: 'absolute',
          //           top: '50%',
          //           left: '50%',
          //           lineHeight: '20px',
          //           fontSize: '14px',
          //           width: 'calc(100% - 40px)',
          //           transform: 'translate(-50%, -50%)',
          //         }}
          //         onClick={() => navigate(`/templates/${template?.id}`)}
          //       />
          //     ) : null}
          //   </div>

          //   {isTemplateUnavailable(template?.templateName) ? (
          //     <HtmlTooltip
          //       title={
          //         <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
          //           This Template is unavailable for your Pricing Plan. Upgrade
          //           or select another one
          //         </Typography>
          //       }
          //     >
          //       <img
          //         src="/locked-mark.svg"
          //         style={{
          //           top: '0',
          //           right: '0',
          //           position: 'absolute',
          //           zIndex: '10',
          //         }}
          //       />
          //     </HtmlTooltip>
          //   ) : null}
          // </Box>
        ))}
      </Grid>
    </CoreLayout>
  );
};
