import { SvgIcon } from '@mui/material';

export const CloseIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.067 15.1827C16.1251 15.2407 16.1712 15.3097 16.2026 15.3855C16.234 15.4614 16.2502 15.5427 16.2502 15.6249C16.2502 15.707 16.234 15.7883 16.2026 15.8642C16.1712 15.94 16.1251 16.009 16.067 16.067C16.009 16.1251 15.94 16.1712 15.8642 16.2026C15.7883 16.234 15.707 16.2502 15.6249 16.2502C15.5427 16.2502 15.4614 16.234 15.3855 16.2026C15.3097 16.1712 15.2407 16.1251 15.1827 16.067L9.99986 10.8835L4.81705 16.067C4.69977 16.1843 4.54071 16.2502 4.37486 16.2502C4.20901 16.2502 4.04995 16.1843 3.93267 16.067C3.8154 15.9498 3.74951 15.7907 3.74951 15.6249C3.74951 15.459 3.8154 15.2999 3.93267 15.1827L9.11627 9.99986L3.93267 4.81705C3.8154 4.69977 3.74951 4.54071 3.74951 4.37486C3.74951 4.20901 3.8154 4.04995 3.93267 3.93267C4.04995 3.8154 4.20901 3.74951 4.37486 3.74951C4.54071 3.74951 4.69977 3.8154 4.81705 3.93267L9.99986 9.11627L15.1827 3.93267C15.2999 3.8154 15.459 3.74951 15.6249 3.74951C15.7907 3.74951 15.9498 3.8154 16.067 3.93267C16.1843 4.04995 16.2502 4.20901 16.2502 4.37486C16.2502 4.54071 16.1843 4.69977 16.067 4.81705L10.8835 9.99986L16.067 15.1827Z"
          fill="#707682"
        />
      </svg>
    </SvgIcon>
  );
};
