import { getUserGroup } from 'helpers';
import {
  SET_USER_INFO,
  SET_USER_GROUP,
  GET_USER,
  UPDATE_USER,
  IS_LOADING,
  UPDATE_USER_EMAIL,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      const userData = action.payload;
      const userGroup = getUserGroup(userData);

      return {
        ...state,
        user: userData,
        userGroup: userGroup,
      };
    }

    case SET_USER_GROUP: {
      // TODO: check if we need this
      return {
        ...state,
        userGroup: action.payload,
      };
    }

    case GET_USER: {
      return {
        ...state,
        userData: action.payload,
      };
    }

    case UPDATE_USER: {
      return {
        ...state,
        userData: action.payload,
      };
    }

    case UPDATE_USER_EMAIL: {
      return {
        ...state,
        userData: { ...state?.userData, email: action.payload },
      };
    }

    case IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    default:
      return state;
  }
};
