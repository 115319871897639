import {
  IS_LOADING,
  GET_DOCUMENT,
  GET_FOLDERS,
  CREATE_FOLDER,
  DELETE_FOLDER,
  CREATE_DOCUMENT,
  UPDATE_FOLDER,
  GET_ALL_DOCUMENTS,
  GET_ALL_DRAFT_DOCUMENTS,
  GET_ALL_FAVOURITES_DOCUMENTS,
  TOGGLE_FAVOURITE_DOCUMENT,
  GET_NON_DRAFT_DOCUMENTS_IN_FOLDERS,
} from './types';

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: data,
      };

    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        documents: data,
      };

    case GET_ALL_DRAFT_DOCUMENTS:
      return {
        ...state,
        draftDocuments: data,
      };

    case GET_ALL_FAVOURITES_DOCUMENTS:
      return {
        ...state,
        favouritesDocuments: data,
      };

    case TOGGLE_FAVOURITE_DOCUMENT:
      return {
        ...state,
        document: data,
      };

    case GET_DOCUMENT:
      return {
        ...state,
        document: { ...state.document, ...data },
      };

    case CREATE_DOCUMENT:
      return {
        ...state,
        documents: { ...state.documents, ...data },
      };

    case UPDATE_FOLDER:
      return {
        ...state,
        folders: data,
      };

    case GET_FOLDERS:
      return {
        ...state,
        folders: data,
      };

    case GET_NON_DRAFT_DOCUMENTS_IN_FOLDERS:
      return {
        ...state,
        nonDraftFolders: data,
      };

    case CREATE_FOLDER:
      return {
        ...state,
        folders: data,
      };

    case DELETE_FOLDER:
      return {
        ...state,
        folders: data,
      };

    default:
      return state;
  }
};
