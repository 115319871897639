export const getUserGroup = (userData) => {
  if (!userData) {
    return 'notAuthorised';
  }
  return userData['cognito:groups'] && userData['cognito:groups'].length
    ? userData['cognito:groups'][0]
    : 'noUserGroup';
};

export const formatDateFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day < 10 ? '0' : ''}${day}/${
    month < 10 ? '0' : ''
  }${month}/${date.getFullYear()}`;
};

export const formatTimeFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const deepCopy = (element) => JSON.parse(JSON.stringify(element));

export const setLocalStorage = (name, value) => {
  const valueToSave = JSON.stringify(value);

  localStorage.setItem(name, valueToSave);
};

export const loadLocalStorage = (name) => {
  const item = localStorage.getItem(name);
  if (!item) return null;

  return JSON.parse(item);
};

export const removeFromLocalStorage = (name) => {
  if (loadLocalStorage(name)) localStorage.removeItem(name);
};

export const removeIdKeys = (obj) => {
  // Check if the current object is an array
  if (Array.isArray(obj)) {
    obj.forEach((item) => removeIdKeys(item)); // Recur for each item in the array
  } else if (typeof obj === 'object' && obj !== null) {
    // Loop through the keys of the object
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // If the key is "_id", delete it
        if (key === '_id') {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          // If the value is an object, recurse into it
          removeIdKeys(obj[key]);
        }
      }
    }
  }
};
